
.detail-container {
  height: 100%;
  display: flex;

  .detail-container-left {
    display: flex;
    flex-direction: column;
    width: 1%;
    flex: 1;

    .paper-title {
      font-size: 24px;
      text-align: center;
      line-height: 1;
      padding: 4px 0 24px;
      border-bottom: 2px solid #E5E5E5;
      margin-bottom: 30px;
    }
  }

  .detail-container-right {
    width: 310px;
    display: flex;
    flex-direction: column;
    background: #F5F7F6;
    position: relative;
    padding-left: 20px;
    box-sizing: border-box;

    .top-shelter {
      padding-left: 20px;
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 20px;
      background: #F5F7F6;
      top: -20px;
      left: 0;
      display: flex;

      .top-shelter-color {
        background: #fff;
        flex: 1;
        width: 1%;
        height: 100%;
      }
    }

    .right-header {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      border-bottom: 1px solid #E5E5E5;
      padding: 0 5px;
      box-sizing: border-box;
      background: #fff;

      .left {
        margin-left: 23px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -13px;
          width: 3px;
          height: 14px;
          background: #E94319;
          transform: translateY(-50%);
        }
      }

      .packUp-btn {
        color: #E94319;
        cursor: pointer;

        .iconfont {
          transition: all .3s;
        }

        &.current {
          .iconfont {
            display: inline-block;
            transform: rotate(180deg);
          }
        }
      }
    }

    .right-wrapper-box {
      height: 1%;
      flex: 1;

      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;

        .el-scrollbar__view {
          background: #fff;
          padding-bottom: 30px;
          box-sizing: border-box;
        }
      }

      .packUp-wrapper {
        padding-top: 10px;
        box-sizing: border-box;
      }

      .right-content {
        .right-item {
          .item-one {
            margin: 10px 0 0 18px;
          }

          &:first-of-type {
            .item-one {
              margin: 0px 0 0 18px;
            }
          }

          .item-two {
            margin-left: 10px;
            margin-right: 18px;

            .item {
              display: inline-block;
              width: calc(100% / 7 - 8px);
              height: 24px;
              margin-left: 8px;
              margin-top: 10px;
              border: 1px solid #D2D2D2;
              border-radius: 4px;
              box-sizing: border-box;
              text-align: center;
              line-height: 24px;

              &.done {
                background: #E5E5E5;
              }
            }
          }
        }
      }
      .submit-btn-bj{
        background: #fff;
        display: flex;
        flex-direction: column;
      }
      .submit-btn {
        margin: 36px 18px 0;
        height: 40px;
        background: linear-gradient(138deg, #FF9A5A 0%, #FF5E03 100%);
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        cursor: pointer;
        box-sizing: border-box;
      }

      .status-wrapper {
        margin: 128px 0 0px;
        display: flex;
        justify-content: center;

        .status-item {
          display: flex;
          align-items: center;
          flex-direction: column;

          .color {
            width: 30px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #D2D2D2;
          }

          .text {
            margin-top: 5px;
          }

          &.done {
            margin-right: 54px;

            .color {
              background: #E5E5E5;
            }
          }
        }
      }
    }
  }
}
